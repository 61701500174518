



















import { Vue, Component, Prop, Watch, PropSync } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { vuex } from "@/store";
import rest from "@/rest";
import { List, Enumerable } from "linq-collections";
import {
  GridOptions,
  IDatasource,
  IGetRowsParams,
  Grid,
  ColDef,
  GridApi,
  IHeaderGroupParams,
  ValueGetterParams,
  ValueFormatterParams,
  CellValueChangedEvent,
  RowNode, CellDoubleClickedEvent, CellClickedEvent, Column
} from "ag-grid-community";
// import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";

// Components
import AvatarCellRenderer from "@/components/CommonCellRenderers/AvatarCellRenderer.vue";
import SwitchCellRenderer from "@/components/CommonCellRenderers/SwitchCellRenderer.vue";
import UserMngActionCellRenderer from "@/components/UserManagement/UserMngActionCellRenderer.vue";
import UserMngActivityStatusCellRenderer from "@/components/UserManagement/UserMngActivityStatusCellRenderer.vue";
import EditUser from "@/components/UserManagement/EditUser.vue";
import DeleteUser from "@/components/UserManagement/DeleteUser.vue";
import UserDetailsDlg from "@/components/UserManagement/UserDetailsDlg.vue";

// Interfaces
import { UpdateUserTableVm } from "@/interfaces/UpdateUserTableVm";
import { PortalUserVM } from "@/interfaces/PortalUserVM";
import { UserTableDataVm } from "@/interfaces/UserTableDataVm";
import Gdpr from "@/views/Gdpr.vue";
import { UserFilterSelectionsVm } from "@/interfaces/UserFilterSelectionsVm";
import moment from "moment";
import { PortalUserVmClass } from "@/classes/PortalUserVmClass";
import { CustomAttributeName } from "@/interfaces/CustomAttributeName";

const auth = namespace("auth");

@Component({
  components: {
    AgGridVue,
    EditUser,
    DeleteUser,
    UserDetailsDlg
  }
})
export default class UserTable extends Vue {
  @PropSync("searchFilter") syncedSearchFilter!: UserFilterSelectionsVm;
  @PropSync("rowCount") syncedRowCount!: number;
  @PropSync("totalCount") syncedTotalCount!: number;

  // $refs!: {
  //   fcEntryGrid: HTMLElement;
  // };

  @auth.Getter isAdmin: any;
  @auth.Getter isOrgAdmin: any;

  updateTableVm: UpdateUserTableVm | null = null;
  agGridOptions = this.getGridOptions();
  agGridColumnDefs: ColDef[] = [];
  agGridFrameworkCmpts = {
    UserMngActionCellRenderer: UserMngActionCellRenderer,
    AvatarCellRenderer: AvatarCellRenderer,
    SwitchCellRenderer: SwitchCellRenderer,
    UserMngActivityStatusCellRenderer: UserMngActivityStatusCellRenderer
  };
  agGridApi = <GridApi>{};
  selectedRowData: PortalUserVM | null = null;
  selectedRowNode: RowNode | null = null;
  selectedRowColumn: Column | null = null;
  selectedColName: string | undefined = "";
  selectedColHeader: string | undefined = "";

  showUserDetailsDialog = false;
  showEditUserDialog = false;
  showDeleteUserDialog = false;
  selectedUser: PortalUserVM = new PortalUserVmClass();
  customAttributeNames: CustomAttributeName[] = [];
  customAttributeNamesLoaded = false;

  async mounted() {
    // this.refresh();
  }

  getGridOptions() {
    return <GridOptions>{
      context: this,
      // datasource: ,
      // data model options
      rowModelType: "infinite",
      rowBuffer: 0,
      cacheBlockSize: 100,
      cacheOverflowSize: 5,
      maxConcurrentDatasourceRequests: 1,
      infiniteInitialRowCount: 50,
      maxBlocksInCache: 200,
      // ux
      rowSelection: "multiple",
      sortingOrder: ["asc", "desc"],
      suppressContextMenu: true,
      preventDefaultOnContextMenu: true,
      stopEditingWhenCellsLoseFocus: true,
      // enableCellTextSelection: true, // Not working with cell renderers
      // components: {
      //   avatarCellRenderer: AvatarCellRenderer,
      // },
      // cbs
      onGridReady: grid => {
        this.agGridApi = grid.api;
        // this.agGridApi.sizeColumnsToFit();
        grid.api.onSortChanged = () => null;
      }
    };
  }

  async getColumnDefs() {
    let colDef = new List();
    await this.getCustomAttributeNames();

    // create custom attribute columns
    let custAttrCols: any[] = [];
    this.customAttributeNames.forEach(a =>{
      custAttrCols.push({
        headerName: a.customName,
        columnGroupShow: "open",
        headerClass: "agTextBold",
        field: a.attributeName,
        valueSetter: "ctx.writeUserData(column.colId, data, oldValue, newValue)",
        singleClickEdit: true,
        editable: true,
        sortable: true,
        resizable: true
      })
    });

    // colDef.pushRange([
    //   {
    //       headerName: "Name",
    //       // columnGroupShow: "open",
    //       headerClass: "agTextBold",
    //       field: "fullName",
    //       width: 160,
    //       resizable: true
    //   },
    //   {
    //     headerName: "E-Mail",
    //     // cellRenderer: "AvatarCellRenderer",
    //     // columnGroupShow: "open",
    //     headerClass: "agTextBold",
    //     field: "email",
    //     width: 200,
    //     // suppressSizeToFit: true,
    //     resizable: true
    //   },
    // ])

    colDef.pushRange([
      {
        headerName: "Aktionen",
        // columnGroupShow: "open",
        headerClass: "agTextBold",
        field: "",
        cellRenderer: 'UserMngActionCellRenderer',
        width: 140,
        resizable: true
      },
      {
        headerName: "Sperren",
        // columnGroupShow: "open",
        headerClass: "agTextBold",
        field: "disabled",
        cellRenderer: 'SwitchCellRenderer',
        valueSetter: "ctx.writeUserData(column.colId, data, oldValue, newValue)",
        width: 90,
        sortable: true,
        resizable: false
      },
      {
        headerName: "Anwenderdaten",
        headerClass: "agTextBold",
        // headerGroupComponent: "FcHeaderGroupComponent",
        children: [
          {
            headerName: "",
            cellRenderer: "AvatarCellRenderer",
            // columnGroupShow: "open",
            // headerClass: "agTextBold",
            field: "avatar",
            width: 60,
            suppressSizeToFit: true,
            resizable: false
          },
          {
            headerName: "Vorname",
            // columnGroupShow: "open",
            headerClass: "agTextBold",
            field: "firstName",
            valueSetter: "ctx.writeUserData(column.colId, data, oldValue, newValue)",
            singleClickEdit: true,
            editable: true,
            sortable: true,
            resizable: true,
          },
          {
            headerName: "Nachname",
            // columnGroupShow: "open",
            headerClass: "agTextBold",
            field: "lastName",
            valueSetter: "ctx.writeUserData(column.colId, data, oldValue, newValue)",
            singleClickEdit: true,
            editable: true,
            sortable: true,
            sort: "asc",
            resizable: true,
          },
          {
            headerName: "Benutzername",
            columnGroupShow: "open",
            headerClass: "agTextBold",
            field: "userName",
            valueSetter: "ctx.writeUserData(column.colId, data, oldValue, newValue)",
            singleClickEdit: true,
            editable: true,
            sortable: true,
            resizable: true,
          },
          {
            headerName: "E-Mail",
            columnGroupShow: "open",
            headerClass: "agTextBold",
            field: "email",
            valueSetter: "ctx.writeUserData(column.colId, data, oldValue, newValue)",
            singleClickEdit: true,
            editable: true,
            sortable: true,
            // suppressSizeToFit: true,
            resizable: true
          },
          {
            headerName: "Personalnummer",
            columnGroupShow: "open",
            headerClass: "agTextBold",
            field: "corporateId",
            valueSetter: "ctx.writeUserData(column.colId, data, oldValue, newValue)",
            singleClickEdit: true,
            editable: true,
            sortable: true,
            resizable: true
          },
          {
            headerName: "Jobbezeichnung",
            columnGroupShow: "open",
            headerClass: "agTextBold",
            field: "jobTitle",
            valueSetter: "ctx.writeUserData(column.colId, data, oldValue, newValue)",
            singleClickEdit: true,
            editable: true,
            sortable: true,
            resizable: true
          },
          {
            headerName: "Abteilung",
            columnGroupShow: "open",
            headerClass: "agTextBold",
            field: "department",
            valueSetter: "ctx.writeUserData(column.colId, data, oldValue, newValue)",
            singleClickEdit: true,
            editable: true,
            sortable: true,
            resizable: true
          },
          {
            headerName: "Kostenstelle",
            columnGroupShow: "open",
            headerClass: "agTextBold",
            field: "costCenter",
            valueSetter: "ctx.writeUserData(column.colId, data, oldValue, newValue)",
            singleClickEdit: true,
            editable: true,
            sortable: true,
            resizable: true
          },
          {
            headerName: "Standort",
            columnGroupShow: "open",
            headerClass: "agTextBold",
            field: "location",
            valueSetter: "ctx.writeUserData(column.colId, data, oldValue, newValue)",
            singleClickEdit: true,
            editable: true,
            sortable: true,
            resizable: true
          },
          {
            headerName: "Organisation",
            columnGroupShow: "open",
            headerClass: "agTextBold",
            field: "organizationName",
            valueSetter: "ctx.writeUserData(column.colId, data, oldValue, newValue)",
            singleClickEdit: true,
            editable: true,
            sortable: true,
            resizable: true
          },
          ...custAttrCols,
          {
            headerName: "Akt. Key",
            columnGroupShow: "open",
            headerClass: "agTextBold",
            field: "activationKey",
            valueSetter: "ctx.writeUserData(column.colId, data, oldValue, newValue)",
            singleClickEdit: true,
            editable: true,
            sortable: true,
            resizable: true
          },
       ]
      },
      {
        headerName: "Zeiten",
        headerClass: "agTextBold",
        // headerGroupComponent: "FcHeaderGroupComponent",
        children: [
          // {
          //   headerName: "Letzte Anmeldung",
          //   // columnGroupShow: "open",
          //   headerClass: "agTextBold",
          //   field: "lastAccessDate",
          //   valueFormatter: "ctx.dateFormatter(x)",
          //   sortable: true,
          //   resizable: true
          // },
          {
            headerName: "Letzte Anmeldung",
            // columnGroupShow: "open",
            headerClass: "agTextBold",
            field: "lastAccessDate",
            cellRenderer: 'UserMngActivityStatusCellRenderer',
            width: 155,
            sortingOrder: ["desc", "asc"],
            sortable: true,
            resizable: true
          },
          {
            headerName: "Eintrittsdatum",
            columnGroupShow: "open",
            headerClass: "agTextBold",
            field: "entryDate",
            valueFormatter: "ctx.dateFormatter(x)", // this.dateFormatter,
            sortable: true,
            resizable: true
          },
          {
            headerName: "Geburtstag",
            columnGroupShow: "open",
            headerClass: "agTextBold",
            field: "birthday",
            valueFormatter: "ctx.dateFormatter(x)", // this.dateFormatter,
            sortable: true,
            resizable: true
          },
          {
            headerName: "DSGVO Zustimmung",
            columnGroupShow: "open",
            headerClass: "agTextBold",
            field: "gdprApprovalDate",
            valueFormatter: "ctx.dateFormatter(x)", // this.dateFormatter,
            sortable: true,
            resizable: true
          },
        ]
      },
    ]);

    return colDef.toArray() as any[];
  }

  async getCustomAttributeNames() {
    if (!this.customAttributeNamesLoaded) {
      await this.loadCustomAttributeNames();
      this.customAttributeNamesLoaded = true;
    }

    return this.customAttributeNames;
  }

  async loadCustomAttributeNames() {
    await rest.url("groupAdmin/loadCustomAttributeNames")
      .get()
      .then((response) => {
        this.customAttributeNames = response;
      })
  }

  dateFormatter(value: Date) {
    // let user = params.data as PortalUserVM;
    // if (!user || !user.gdprApprovalDate) return "-";
    // console.log("date", value);
    if (!value) return "-";

    let dateAsString = moment(value).format("DD.MM.yyyy");
    return dateAsString;
  }

  // Not working for rowModelType: "infinite"
  // async loadData() {
  //   // console.log("Load data");
  //   let result = await rest().post("TargetingApi/LoadAmTableData", this.filterSelections);
  //   this.tableData = result.data;
  // }

  onUserDetails(user: PortalUserVM) {
    this.selectedUser = user;
    this.showUserDetailsDialog = true;
  }

  onEditUser(user: PortalUserVM) {
    this.selectedUser = user;
    this.showEditUserDialog = true;
  }

  onDeleteUser(user: PortalUserVM) {
    this.selectedUser = user;
    this.showDeleteUserDialog = true;
  }

  async loadData(): Promise<void> {
    // console.log("loadData");
    if (!this.agGridApi)
      return;
    this.agGridApi.ensureIndexVisible(0);
    this.agGridApi.setDatasource(new UserAgGridDataSource());
  }

  async updateTable(): Promise<void> {
    if (!this.agGridApi)
      return;
    // console.log("Update data");

    // Workaround: On changing CollumnDefs some columns are mistakenly added to the end
    this.agGridColumnDefs = <any>[];
    await this.$globalHelper.delay(50);
    // -------------------

    this.agGridColumnDefs = await this.getColumnDefs();
    await this.$globalHelper.delay(50);
    this.agGridApi.ensureIndexVisible(0);
    this.agGridApi.setDatasource(new UserAgGridDataSource());
  }

  refresh(): void {
    this.agGridApi.refreshCells();
  }

  // Not working for rowModelType: "infinite"
  // async clear(): Promise<void> {
  // //   this.tableData = [];
  // }

  resetColumns(): void {
    this.agGridOptions.columnApi!.resetColumnState();
  }

  adjustColWidth(): void {
    this.agGridApi.sizeColumnsToFit();
  }

  async exportUserExcel() {
    let updateTableVm = <UpdateUserTableVm>{
      getRows: {
        startRow: 0, // will be ignored
        endRow: 0, // will be ignored
        columnName: "lastName",
        ascending: true
      },
      searchFilter: this.syncedSearchFilter,
    };

    await rest.url("orgAdmin/createUserExcel")
      .post(updateTableVm)
      .then((fileName) => {
        if(fileName)
          this.$globalHelper.download(`api/resource/Temp/${fileName}`, fileName);
      })
  }


  // isCellEditable(columnName: string): boolean {
  //   // console.log("isResponsibleCellEditable: " + columnName);

  //   if (columnName == "FirstName" ||
  //     columnName == "LastName"

  //   )
  //     return true;

  //   return false;
  // }

  // isCellInlineEditable(columnName: string): boolean {
  //   // console.log("isCellInlineEditable: " + columnName);
  //   if (
  //     columnName == "FirstName" ||
  //   )
  //     return true;
  //   return false;
  // }

  async writeUserData(columnName: string, user: PortalUserVM, oldValue: any, newValue: any) {
    // console.log("writeUserData colName", columnName);
    // console.log("writeUserData new value", newValue);
    if (newValue == oldValue)
      return false;

    user[columnName] = newValue;

    // let result: UserTableDataVm = await rest.url("admin/loadPortalUsers").post(loadParams).finally(() => this.tableLoading = false);
    let result = await rest.url("orgAdmin/createOrUpdateUser").post(user);

    return true;
  }

  // getCurrentValue(rowName: string) {
  //   // console.log("getCurrentValue: ", data);
  // }

  // setCurrentValue(user: PortalUserVM, newValueString: string) {
  //   // console.log(newValueString);
  //   return true;
  // }

  // ag-grid value formatter
  // https://www.ag-grid.com/javascript-grid-value-formatters/
  // formatCellValue(value: number, rowName: string) {
  //   let userLang = navigator.language;
  //   // console.log("The language is: " + userLang);
  //   // console.log(rowName);

  //   if (rowName == "User")
  //     return this.toPercent(value);
  //   return value.toLocaleString(userLang, { maximumFractionDigits: 0 });
  // }

  toPercent(value: number) {
    return (value * 100).toFixed(1) + "%";
  }

  toCurrency(value: number): string {
    let userLang = navigator.language;
    return new Intl.NumberFormat(userLang, { style: 'currency', currency: 'EUR' }).format(value);
  }

  /**
   * Events
   */

  onCellValueChanged(params: CellValueChangedEvent) {
    // console.log("onCellValueChanged");
    // console.log(params);

    // this.updateFcGmInPercent();
    // if (params.colDef.field == "Q1.CurrentValue") {
    //   params.data.Q1.ForecastDeviation = params.data.Q1.LastValue - params.data.Q1.CurrentValue;
    //   console.log(params.data.Q1.ForecastDeviation);
    // }
  }

  onRightClick() {
  }

  onCellClick(e: CellClickedEvent) {
    if (!e.data || !e.colDef)
      return;

    // console.log("onCellClick");
    // console.log(e.column);
    // console.log(e.colDef);
    // console.log(e);

    this.selectedRowData = e.data as PortalUserVM;
    this.selectedRowNode = e.node;
    this.selectedRowColumn = e.column;
    this.selectedColName = e.colDef.field?.split(".")[0] ?? "";
    this.selectedColHeader = e.colDef.headerName;

    // if (this.selectedColName && this.isCellInlineEditable(this.selectedColName)) {
    // }
    return;
  }

  async onUpdateSelectedCell(newValue: number) {
    console.log("Update selected cell: " + newValue);
    if (!this.selectedRowNode || !this.selectedRowColumn)
      return;

    this.selectedRowNode.setDataValue(this.selectedRowColumn, newValue);
    // if (this.selectedRowData?.RowType == ForecastingValueType.Rev || this.selectedRowData?.RowType == ForecastingValueType.GM) {
    //   await this.loadData();
    // }
  }

  /**
   * Getters
   */

  /**
   * Helper
   */
}

export class UserAgGridDataSource implements IDatasource {
  getRows = async (params: IGetRowsParams) => {
    let searchFilter: UserFilterSelectionsVm = params.context.searchFilter;
    // sort model
    let sortColumnName: string | null = null;
    let sortAsc = false;
    // check sort
    let sortAgGrid = params.sortModel[0] ?? null;
    if (sortAgGrid) {
      // console.log(sortAgGrid.colId);
      sortColumnName = sortAgGrid.colId.split(".")[0];
      if (sortAgGrid.sort == "desc") sortAsc = false;
      if (sortAgGrid.sort == "asc") sortAsc = true;
    }

    params.context.updateTableVm = <UpdateUserTableVm>{
      getRows: {
        startRow: params.startRow,
        endRow: params.endRow,
        columnName: sortColumnName,
        ascending: sortAsc
      },
      searchFilter: searchFilter,
    };

    await rest
      .url("orgAdmin/loadUsers")
      .post(params.context.updateTableVm)
      .then(result => {
        // console.log(result)
        let tableData = result as UserTableDataVm;
        params.context.syncedRowCount = tableData.hits;
        params.context.syncedTotalCount = tableData.total;
        params.successCallback(tableData.portalUsers as any[], tableData.hits);
      });
  };
}

export class NoDataDatasource implements IDatasource {
  getRows = (params: IGetRowsParams) => {
    params.successCallback([]);
  };
}
