




































































































































































  import _ from "lodash";
  import rest from "@/rest";
  import { RestResponse } from "@/interfaces/RestResponse";
  import wretch, { WretcherError } from "wretch";
  import { Component, Vue } from "vue-property-decorator";
  import { namespace } from "vuex-class";
  // Interfaces
  import { ParamDictionary } from "@/interfaces/ParamDictionary";
  // Components
  import TusFileDrop from "@/components/Shared/TusFileDrop.vue";
  import { ExCategory1 } from "@/interfaces/ExCategory1";
  import { ExCategory2 } from "@/interfaces/ExCategory2";
  import { List } from "linq-collections";
  // import de from 'vuetify/src/locale/de';

  const auth = namespace("auth");

  @Component({
    components: {
      TusFileDrop,
    }
  })
  export default class ScormManager extends Vue {
    $refs!: {
      detailsPanel: HTMLFormElement;
      qrcodeCanvas: HTMLCanvasElement;
    };
    @auth.Getter isAdmin: any;

    paramDir: ParamDictionary = { dictionary: {}};
    directorySize: number = 0;


    fileUploadParam: ParamDictionary = { dictionary: {}};
    fileListParam: ParamDictionary = { dictionary: {}};
    fileDownloadParam: ParamDictionary = { dictionary: {}};
    bundleFileList: string[] | null = null;
    selectedFile: string | null = null;
    newGuid: string | null = null;
    fileImportList: string[] = [];
    fileImportBusy = false;
    fileUploadList: string[] = [];
    fileUploadBusy = false;
    categoriesArchiveLoading = false;
    bundleZipLoading = false;
    archiveLog = "";

    async mounted() {
      this.getDirectorySize();
    }

    updateView() {
    //   this.getGroupedExBundles();
    //   this.getBundleFiles();
    }

    async processFileImportList() {
      if (this.fileImportList.length == 0 || this.fileImportBusy)
        return;

      this.fileImportBusy = true;
      let nextFile = this.fileImportList.pop();
      this.fileUploadParam.dictionary!["FileName"] = nextFile!;
      await rest.url("contentManager/importExerciseExcelOrZipArchiveFile").post(this.fileUploadParam)
      .then(async (response) => {
        if (!response) {
        //   await this.loadCategories("update", "");
        //   await this.getGroupedExBundles();
          return;
        }
        if (nextFile?.endsWith(".xlsx")) {
          // select category of uploaded Excel file
        //   await this.loadCategories("selectGuid", response);
        //   await this.getGroupedExBundles();

        //   let group = new List(this.exBundleGroups).singleOrDefault(g => new List(g.bundles!).any(b => b.guid == response));
        //   if (group) {
        //     // open expansion panel
        //     let index = this.exBundleGroups.indexOf(group);
        //     this.selectedBundleGroupPanel = index;
        //     // select bundle
        //     let bundle = new List(group.bundles!).single(b => b.guid == response);
        //     // this.showExerciseBundle(bundle);
        //   }
          return;
        }

        this.archiveLog += "<br<br>" + response;
        // this.showArchiveLog = true;
        // await this.loadCategories("update", "");
        // await this.getGroupedExBundles();
      })
      .catch(err => {
      })
      .finally(() => {
        this.fileImportBusy = false;
        this.processFileImportList();
      });
    }

    async processFileUploadList() {
      if (this.fileUploadList.length == 0 || this.fileUploadBusy)
        return;

      this.fileUploadBusy = true;
      let nextFile = this.fileUploadList.pop();

      // this.fileUploadParam.dictionary!["BundleId"] = this.selectedBundle!.id.toString();
      // this.fileUploadParam.dictionary!["LocalPath"] = this.selectedBundle!.localPath!; // Shared Resource and Temp dir didn't have a bundle ID
      // this.fileUploadParam.dictionary!["FileName"] = nextFile!;
      await rest.url("contentManager/moveFileToLocalDir").post(this.fileUploadParam)
      .then(async (response) => {
        if (!response) {
          return;
        }
        // select category of uploaded Excel file
        // await this.loadCategories("selectGuid", response);
        // await this.getGroupedExBundles();

        // let group = new List(this.exBundleGroups).singleOrDefault(g => new List(g.bundles!).any(b => b.guid == response));
        // if (group) {
        //   // open expansion panel
        //   let index = this.exBundleGroups.indexOf(group);
        //   this.selectedBundleGroupPanel = index;
        //   // select bundle
        //   let bundle = new List(group.bundles!).single(b => b.guid == response);
        //   this.showExerciseBundle(bundle);
        // }
      })
      .catch(err => {
      })
      .finally(() => {
        this.fileUploadBusy = false;
        this.processFileUploadList();
      });

      // this.getGroupedExBundles();
      // this.getBundleFiles();
    }

    async onScormFileUploaded(response: any) {
    if (!response.success) {
      this.$store.commit("ux/SB_FAILURE", {
        message: response.message,
        timeout: 0
      });
      return;
    }

    // Add file to list and start import on server
    this.fileImportList.push(response.fileName);
    this.processFileImportList();
  }

    async getDirectorySize() {
      // let pathParams = { dictionary: { LocalPath: this.selectedBundle.localPath!} };
      let pathParams = { dictionary: { LocalPath: ""} };
      this.directorySize = await rest.url("contentManager/getDirectorySize").post(pathParams);
    }

    get dirSize() {
      if (!+this.directorySize) return '0 Bytes'

      const k = 1024;
      const dm = 2;
      const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

      const i = Math.floor(Math.log(this.directorySize) / Math.log(k))

      return `${parseFloat((this.directorySize / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    // replace with vuex variant
    get backgroundColor() {
      let currentThemeName = this.$vuetify.theme.dark ? 'dark' : 'light';
      return this.$vuetify.theme.themes[currentThemeName].background;
    }
  }
  