



































































































































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop, PropSync } from "vue-property-decorator";
import {
  PortalUserVM,
  SelectListItem,
  GroupVm,
} from "@/interfaces/PortalUserVM";
import { GroupExtVm, SubGroupVm } from "@/interfaces/GroupExtVm";

// import { VRowExpandTransition } from "vuetify/lib"; // ???
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { List } from "linq-collections";
import { namespace } from "vuex-class";
import { PortalUserVmClass } from "@/classes/PortalUserVmClass";
import { ParamSubGroupMemberSuggestionVm } from "@/interfaces/ParamSubGroupMemberSuggestionVm";

const auth = namespace("auth");

@Component({
  components: {}
})
export default class EditSubGroup extends Vue {
  @Prop() subGroup!: SubGroupVm;
  @Prop() parentGroup!: GroupExtVm;
  @PropSync("showDialog") syncedShowDialog!: boolean;

  // @auth.Getter isAdmin: any;
  // @auth.Getter isOrgAdmin: any;
  // @auth.Getter isGroupAdmin: any;

  init = true;
  formValid = false;
  isBusy = false;
  isSearching = false;
  search: string | null = null;
  subGroupMembers: PortalUserVM[] = [];
  userSuggestions: PortalUserVM[] = [] as PortalUserVM[];
  selectedUser: PortalUserVM | null = null;
  windowInnerHeight = 0;

  $refs!: {
    editClassForm: HTMLFormElement;
    nameTextField: HTMLFormElement;
  };

  async mounted() {
    this.setWindowHeight();
  }

  created() {
    window.addEventListener("resize", this.setWindowHeight);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.setWindowHeight);
    clearAllBodyScrollLocks();
  }

  setWindowHeight() {
    this.windowInnerHeight = window.innerHeight;
  }

  @Watch("showDialog")
  async onShowDialogChanged(open: boolean) {
    if (open) {
      // On dialog open
      this.subGroupMembers = [];
      this.userSuggestions = [];

      // Init new subgroup
      if (this.isNew) {
        this.subGroup.parentGroupId = this.parentGroup.id;
      } else {
        await this.loadSubGroupWithMembers();
      }

      this.init = false;
      await this.loadSuggestions();
      this.$refs.nameTextField.focus();

      // Disable body scroll on iOS
      this.$nextTick(async () => {
        await this.$globalHelper.delay(10);
        const modal = document.querySelector('.modal');
        disableBodyScroll(modal);
      });
    } else {
      this.init = true;
    }
  }

  async loadSubGroupWithMembers() {
    await rest.url("groupAdmin/getSubGroupWithMembers").query({ subGroupId: this.subGroup.id }).get()
      .then((response: SubGroupVm) => {
        this.subGroupMembers = response?.users ?? [];
      })
  }

  @Watch("search")
  async onSearch(val) {
    if (this.init) return;

    this.debounce();
  }
  debounce = _.debounce(this.loadSuggestions, 700, { leading: true, trailing: true});

  async loadSuggestions() {
    if (this.isSearching) return;

    this.isSearching = true;
    const subGroupUserIds = new List(this.subGroupMembers).select(u => u.id).toArray();
    let param = <ParamSubGroupMemberSuggestionVm>{
      groupId: this.parentGroup.id,
      subGroupUserIds: subGroupUserIds,
      search: this.search
    };

    rest
      .url("groupAdmin/loadSubGroupMemberSuggestion")
      .post(param)
      .then(result => {
        // console.log(result);
        this.userSuggestions = result;
      })
      .finally(() => this.isSearching = false)
      .catch(err => {
        console.log(err)
      });
  }

  get dialogTitle() {
    if (this.isNew) {
      return "Neue Untergruppe anlegen";
    }
    return "Untergruppe bearbeiten";
  }

  get isNew() {
    if (!this.subGroup) return true;
    return this.subGroup.id == 0;
  }

  async onUserSelected() {
    if (this.selectedUser == null)
      return;

    this.subGroupMembers.push(this.selectedUser);

    await this.loadSuggestions();
    this.selectedUser = new PortalUserVmClass();
  }

  async onRemoveUser(user: PortalUserVM) {
    if (this.subGroupMembers == null)
      return;

    let index: number = this.subGroupMembers.indexOf(user);
    this.subGroupMembers.splice(index, 1);

    await this.loadSuggestions();
    this.selectedUser = new PortalUserVmClass();
  }

  closeDialog() {
    this.syncedShowDialog = false;
  }

  async saveSubGroup() {
    if (!this.$refs.editClassForm.validate()) return;
    this.isBusy = true;
    this.subGroup.users = this.subGroupMembers;

    await rest.url("groupAdmin/saveSubGroup").post(this.subGroup)
      .then((subGroupVm: SubGroupVm) => {
        this.$emit("subGroupSaved:EditSubGroup", subGroupVm);
        this.closeDialog();
      })
      .finally(() => this.isBusy = false)
  }

  get listHeight() {
    if (this.$vuetify.breakpoint.xs)
      return this.windowInnerHeight - 290;
    else
      return this.windowInnerHeight * 0.9 - 300;
  }

  mailRules = [
    (v: string) => !!v || "Email is required",
    (v: string) =>
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) || "Keine gültige E-Mail-Adresse"
  ];

  usernameRules = [
    (v: string) => !!v || "Benutzername erforderlich",
    (v: string) => (v && v.length >= 3) || "Benutzername zu kurz."
  ];

  keyRules = [
    //(v: string) => !!v || "Schlüssel erforderlich",
    (v: string) => v.length == 0 || v.length > 7 || "Schlüssel zu kurz."
  ];

  nameRules = [
    (v: string) => !!v || "Name erforderlich",
    (v: string) => (v && v.length > 2) || "Name zu kurz."
  ];

  passwordRules = [
    (v: string) => !!v || "Passwort erforderlich",
    (v: string) => (v && v.length > 7) || "Passwort ist zu kurz."
  ];
}
