import { ActionTree, GetterTree, MutationTree, Module } from "vuex";
import { RootStore } from "../types";
import { List } from "linq-collections";
import { NotifyStore } from "../types/notify";
import { getMessaging, getToken, Messaging } from "firebase/messaging";
import { FirebaseSubscribeToTopicVm } from "@/interfaces/FirebaseSubscribeToTopicVm";
import rest from "@/rest";
// import { vuex } from '@/store';
import Store, { vuex } from "@/store";
import { TopicNotificationVm } from "@/interfaces/TopicNotificationVm";
import { TopicVm } from "@/interfaces/TopicVm";
import { State } from "vuex-class";
import { UnsubscribedTopic } from "@/interfaces/UnsubscribedTopic";
import { UserNotificationVm } from "@/interfaces/UserNotificationVm";
import { UpdateTopicSubscriptionsVm } from "@/interfaces/UpdateTopicSubscriptionsVm";
import { action, createModule, mutation } from "vuex-class-component";
// import Helper from '@/classes/helper';
// import i18n from '@/i18n';

const VuexModule = createModule({
  strict: false,
  namespaced: "notify",
});

export class Notify extends VuexModule {
  // state
  fcmToken = "";
  fcMessaging = <Messaging>{};
  // notifications = <TopicNotificationVm[]>[]
  topicNotifications = <TopicNotificationVm[]>[];
  userNotifications = <UserNotificationVm[]>[];

  // getters
  get hasNewTopicNotifications() {
    return this.topicNotifications.findIndex((n) => !n.noted) >= 0;
  }
  get newTopicNotificationCount() {
    return this.topicNotifications.filter((n) => !n.noted).length;
  }
  get hasNewUserNotifications() {
    return this.userNotifications.findIndex((n) => !n.noted) >= 0;
  }
  get newUserNotificationCount() {
    return this.userNotifications.filter((n) => !n.noted).length;
  }
  get isPushSupportedByBrowser() {
    return (
      typeof window !== "undefined" &&
      "serviceWorker" in navigator &&
      "PushManager" in window &&
      "Notification" in window
    );
  }

  // actions

  @action async registerFcm() {
    this.setMessaging(getMessaging());

    // Register client for notifications
    // https://firebase.google.com/docs/cloud-messaging/js/client#retrieve-the-current-registration-token

    let fcmToken = await getToken(this.fcMessaging, {
      vapidKey:
        "BMnURyglFPVeWF6l7xV23tyzZJYe5TJ920yQufXOymS_4tn6Y-WcZtp5BbX4wGa3cblRceIUKVT4Bvvjgcs3gi0",
      serviceWorkerRegistration:
        await window.navigator.serviceWorker.getRegistration(),
    }).catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // commit("ux/SB_FAILURE", {
      //   message: `FCM error: ${err}`,
      //   timeout: 0
      // }, { root: true });
    });

    if (fcmToken) {
      this.setFcmToken(fcmToken);
      console.log("Firebase registration successful. Token: " + fcmToken);

      // dispatch("subscribeTopics");
      // await this.fcmRegisterClient();
      this.fcmRegisterClient();
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
    }

    const broadcast = new BroadcastChannel("hessingbcch");
    broadcast.onmessage = (msg: MessageEvent) => {
      console.log("broadcast received: ", msg.data);
      if (msg.data === "open") {
        window.open("https://l3rn-online.de", "_blank");
        // vuex.ux.SB_SUCCESS(`${VueInstance.$t('shared.notification.opened')}: ${msg.data}`);
        vuex.ux.SB_SUCCESS({
          message: `Notification opened: ${msg.data}`,
          timeout: 3000,
        });
      } else {
        this.loadTopicNotifications();
        this.loadUserNotifications();
        // vuex.ux.SB_SUCCESS(`${VueInstance.$t('shared.notification.received')}: ${msg.data}`);
        vuex.ux.SB_SUCCESS({
          message: `Neue persönliche Benachrichtigung: ${msg.data}`,
          timeout: 3000,
        });
      }
      //  let msgObj = JSON.parse(msg.data)
      //  console.log(msgObj)
      //  this.addTopicNotification(msgObj)
      //console.log(msgObj.notification.body)

      //      window.open(payload.notification.click_action , '_blank');
      //window.open('http://localhost:8611/connect' , '_blank');
    };
  }

  @action async fcmRegisterClient() {
    let result: boolean = await rest
      .url("notification/fcmRegisterClient")
      .query({ token: this.fcmToken, userAgent: navigator.userAgent })
      .get();
    if (result == true) {
      vuex.ux.SB_SUCCESS({
        message: "Erfolgreich für Benachrichtigungen registriert",
        timeout: 3000,
      });
    } else {
      vuex.ux.SB_SUCCESS({
        message: "Fehler beim registriert für Benachrichtigungen",
        timeout: 3000,
      });
    }
  }

  @action async updateTopics(topics: TopicVm[]) {
    //await rest.url('notification/saveUnsubscribedTopics').post(topics.filter(t => !t.selected));
    let params = <UpdateTopicSubscriptionsVm>{
      registrationToken: this.fcmToken,
      topics: topics,
    };
    let result: boolean = await rest
      .url("notification/updateTopicSubscriptions")
      .post(params);
    if (result == true) {
      vuex.ux.SB_SUCCESS({
        message: "Erfolgreich gespeichert",
        timeout: 3000,
      });
    } else {
      vuex.ux.SB_SUCCESS({
        message: "Fehler beim speichern der Themen!",
        timeout: 3000,
      });
    }
  }

  @action async loadTopicNotifications() {
    rest
      .url("notification/getTopicNotifications")
      .get()
      .then(async (result: TopicNotificationVm[]) => {
        await this.saveTopicNotifications(result);
      })
      .catch((err) => {
        // Helper.handleException(err);
      });

    // if (vuex.onlineChecker.isOnline) {
    // } else {
    //   const pData = localStorage.getItem(this.ukeyNotifications);
    //   if (!pData) {
    //     return;
    //   }
    //   let list = JSON.parse(pData) as TopicNotificationVm[];
    //   this.setTopicNotifications(list);
    // }
  }

  @action async saveTopicNotifications(list: TopicNotificationVm[]) {
    list = new List(list)
      .orderBy((n) => n.noted)
      .thenByDescending((n) => n.createdDate)
      .toArray();
    this.setTopicNotifications(list);
    // let tempList = Helper.copyObject(list);
    // tempList.forEach(n => {
    //   n.image = '';
    // });
    // localStorage.setItem(this.ukeyNotifications, JSON.stringify(tempList));
  }

  @action async topicNotificationsNoted() {
    if (this.hasNewTopicNotifications) {
      // set noted flag local !!! Robert: Maybe action should be changed to mutation !!!
      this.topicNotifications.forEach((n) => {
        n.noted = true;
      });
      // let list = Helper.copyObject(this.notifications);
      // list.forEach(n => {
      //   n.image = '';
      // });
      // localStorage.setItem(this.ukeyNotifications, JSON.stringify(list));

      await this.sendTopicNotificationNoted();
      // if (vuex.onlineChecker.isOnline) {
      //   await this.sendTopicNotificationNoted();
      // }
    }
  }

  @action async sendTopicNotificationNoted() {
    // send noted flag to backend
    rest
      .url("notification/topicNotificationsNoted")
      .post()
      .then((result) => {
        // console.log(result);
      })
      .catch((err) => {
        // Helper.handleException(err);
      });
  }

  // user notifications
  @action async loadUserNotifications() {
    rest
      .url("notification/getUserNotifications")
      .get()
      .then(async (result: UserNotificationVm[]) => {
        await this.saveUserNotifications(result);
      })
      .catch((err) => {
        // Helper.handleException(err);
      });
  }

  @action async saveUserNotifications(list: UserNotificationVm[]) {
    list = new List(list)
      .orderBy((n) => n.noted)
      .thenByDescending((n) => n.createdDate)
      .toArray();
    this.setUserNotifications(list);
  }

  @action async userNotificationsNoted() {
    if (this.hasNewUserNotifications) {
      // set noted flag local !!! Robert: Maybe action should be change to mutation !!!
      this.userNotifications.forEach((n) => {
        n.noted = true;
      });
      this.sendUserNotificationNoted();
      // if (vuex.onlineChecker.isOnline) {
      //   await this.sendTopicNotificationNoted();
      // }
    }
  }

  @action async sendUserNotificationNoted() {
    rest
      .url("notification/userNotificationsNoted")
      .post()
      .then((result) => {
        // console.log(result);
      })
      .catch((err) => {
        // Helper.handleException(err);
      });
  }

  // mutations

  @mutation setMessaging(v: Messaging) {
    this.fcMessaging = v;
  }
  @mutation setFcmToken(v: string) {
    this.fcmToken = v;
  }
  @mutation setTopicNotifications(list: TopicNotificationVm[]) {
    this.topicNotifications = list;
  }
  // addTopicNotification(msgObj: any) {
  //   this.topicNotifications.push(msgObj);
  // },
  @mutation setUserNotifications(list: UserNotificationVm[]) {
    this.userNotifications = list;
  }
}
