



























import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";

const globals = namespace("globals");

@Component({
  components: {},
})
export default class ContentFrameWithImage extends Vue {
  @Prop() title!: string;
  @Prop() text!: string;
  @Prop() ImgSrc!: string;
  @Prop() showImageLeft!: boolean;
  @Prop() size!: string; // 24, evtl.: x-small, small, medium, large, x-large
  @Prop() color!: string;
  @Prop() weight!: string; // lighter, normal, bold, bolder, 100,200,300,400,500,600,700,800,900
  @Prop() fontStyle!: string; // normal, italic, oblique

  // $refs!: {};

  mounted() {}

	get fontSize() {
			if (this.size == null)
					return "18px";
			if (isNaN(Number(this.size))) // Number(this.size) != NaN)
					return this.size;
			return this.size + "px";
	}

	get lineHeight() {
			let fs = this.fontSize.replace("px", "");
			if (isNaN(Number(fs)))
					return this.fontSize;

			return Number(fs) + 2 + "px";
	}

	get showNoImage() {
		return !this.ImgSrc;
	}

	get showLeftImage() {
    if (this.showNoImage)
      return false;

		if (this.showImageLeft) {
			return true
		} else {
			return this.$vuetify.breakpoint.xsOnly;
		}
	}

	get showRightImage() {
    if (this.showNoImage)
      return false;

		if (this.showImageLeft) {
			return false
		} else {
			return !this.$vuetify.breakpoint.xsOnly;
		}
	}

  // get showLeftImage1() {
  //   return true;
  // }

  // get showLeftImage2() {
  //   return false;
  // }

  // get showRightImage1() {
  //   return this.$vuetify.breakpoint.xsOnly;
  // }

  // get showRightImage2() {
  //   return !this.$vuetify.breakpoint.xsOnly;
  // }
}
