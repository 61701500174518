







































































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop, PropSync } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { List } from "linq-collections";
import { vuex } from "@/store";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

// interfaces
import { UserTagVm } from "@/interfaces/UserTagVm";

// components
import EditTag from "@/components/UserManagement/EditTag.vue";

const globals = namespace("globals");

@Component({
  components: {
    EditTag
  }
})
export default class ManageTags extends Vue {
  // @PropSync("showDialog") syncedShowDialog!: boolean;

  showDialog = false;
  showEditTagDlg = false;
  busy = false;
  search: string | null = null;
  isNewTag = false;
  selectedTag: UserTagVm | null = null;
  tags: UserTagVm[] = [];

  mounted() {
  }

  @Watch("showDialog")
  async onShowDialogChanged(open: boolean) {
    if (open) {
      // On dialog open
      this.search = null;
      this.tags = [];
      await this.$globalHelper.delay(10);
      this.loadUserTags();

      // Disable body scroll on iOS
      this.$nextTick(async () => {
        await this.$globalHelper.delay(10);
        const modal = document.querySelector('.modal');
        disableBodyScroll(modal);
      });
    } else {
      // Dialog closing
      clearAllBodyScrollLocks();
    }
  }

  async loadUserTags() {
    await rest.url("groupAdmin/loadUserTags")
      .get()
      .then((response) => {
        this.tags = response;
      })
  }

  get filteredTags() {
    let tags = new List(this.tags).toList(); //.orderBy(t => t.name);
    if (this.search != null)
      tags = tags.where(t => t.name?.toUpperCase().includes(this.search?.toUpperCase() ?? "") ?? false).toList();

      return tags.toArray();
  }

  onNew() {
    this.selectedTag = <UserTagVm> { id: 0, name: "" };
    this.isNewTag = true;
    this.showEditTagDlg = true;
  }

  onEdit(item) {
    this.selectedTag = item;
    this.isNewTag = false;
    this.showEditTagDlg = true;
  }

  onDelete(item: UserTagVm) {
    const itemIdx = new List(this.tags).indexOf(item);
    new List(this.tags).removeAt(itemIdx);
  }

  addTag() {
    if (!this.selectedTag) return;
    this.tags.push(this.selectedTag);
  }

  async saveTags() {
    this.busy = true;
    await rest.url("groupAdmin/saveUserTags")
      .post(this.tags)
      .then((response) => {
        this.$emit('tagsSaved:EditTags', true);
        this.closeDialog();
      })
      .finally(() => {
        this.busy = false;
      })
  }

  beforeDestroy() {
    clearAllBodyScrollLocks();
  }

  closeDialog() {
    this.showDialog = false;
  }

  // async saveUser() {
  //   if (!this.editUser) return;
  //   if (!this.$refs.editUserForm.validate()) return;
  //   if (this.busy) return;

  //   this.busy = true;
  //   this.editUser.userName = this.editUser.userName?.trim() ?? null;
  //   this.editUser.firstName = this.editUser.firstName?.trim() ?? null;
  //   this.editUser.lastName = this.editUser.lastName?.trim() ?? null;
  //   this.editUser.nickname = this.editUser.nickname?.trim() ?? null;
  //   this.editUser.email = this.editUser.email?.trim() ?? null;
  //   this.editUser.corporateId = this.editUser.corporateId?.trim() ?? null;
  //   this.editUser.birthday = this.birthdayAsIso ? new Date(this.birthdayAsIso) : null;
  //   // this.editUser.birthday = this.birthdayAsIso ? moment.utc(this.birthdayAsIso).toDate() : null;

  //   await rest.url(this.saveUserUrl)
  //     .post(this.editUser)
  //     .then((response) => {
  //       this.$emit('userSaved:EditUser', true);
  //       this.closeDialog();
  //     })
  //     .finally(() => {
  //       this.busy = false;
  //     })
  // }
}
