

















































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Login from "@/components/Login.vue"; // @ is an alias to /src
import ContentFrameWithImage from "@/components/LandingPage/ContentFrameWithImage.vue"; // @ is an alias to /src
import { vuex } from "@/store";
import rest from "@/rest";
import { CustomerMessageVm } from "@/interfaces/CustomerMessageVm";
import { namespace } from "vuex-class";
import { List } from "linq-collections";

const ux = namespace("ux");

@Component({
  components: {
    Login,
    ContentFrameWithImage
  }
})
export default class LandingPage extends Vue {
  @ux.State darkTheme: any;
  showAutomation: boolean = false;
  showUseEverywhere: boolean = false;
  showAdaptedLearning: boolean = false;
  showLoginDialog: boolean = false;

  // contact view
  kontaktFormValid = false;
  customerMsg = <CustomerMessageVm>{
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    phoneNumber: "",
    message: ""
  }
  gdprAccepted = false;
  messageSending = false;

  menuItems = [
    {text: "Übersicht", value: "overview"},
    // {label: "Neuigkeiten", value: "news"},
    {text: "Preise", value: "prices"},
    {text: "Über uns", value: "aboutUs"},
    {text: "Kontakt", value: "contact"},
  ];
  selectedMenuItemValue = this.menuItems[0].value;
  selectedWindow = 0;

  mounted() {
    this.applyOrAdjustDigiClassTheme();
  }

  metaInfo() {
    return {
      titel: "L3RN online Home",
      meta: [
        {
          name: "description",
          content: "Die kostengünstige Schulungs-App"
        }
      ]
    }
  }

  applyOrAdjustDigiClassTheme() {
    // adjust old style
    let themeLink = document.getElementById('exerciseTheme');
    if (themeLink) {
      if (this.darkTheme)
        themeLink.setAttribute("href", "/styles/exercises-dark.css");
      else
        themeLink.setAttribute("href", "/styles/exercises-light.css");

      return;
    }

    // Create new one
    let styles = document.createElement('link');
    styles.type="text/css";
    styles.rel="stylesheet";
    styles.id="exerciseTheme";
    if (this.darkTheme)
      styles.href="/styles/exercises-dark.css";
    else
      styles.href="/styles/exercises-light.css";
    document.head.appendChild(styles);
  }

  openLoginDialog() {
    // value is use as trigger => just invert it
    this.showLoginDialog = !this.showLoginDialog;
  }

  onMenuItemClicked(item) {
    this.selectedMenuItemValue = item.value;
  }

  @Watch("selectedMenuItemValue")
  onMenuItemChanged(value) {
    let selectedItem = new List(this.menuItems).single(i => i.value == value);
    let idxOfSelectedItem = this.menuItems.indexOf(selectedItem);
    this.selectedWindow = idxOfSelectedItem;
  }

  selectPrevTab() {
    this.selectedWindow = this.selectedWindow - 1 < 0 ? 0 : this.selectedWindow - 1;
  }

  selectNextTab() {
    this.selectedWindow = this.selectedWindow + 1 === this.menuItems.length ? this.menuItems.length - 1 : this.selectedWindow + 1;
  }

  @Watch("selectedWindow")
  onCarouselDotSelectionChange(value) {
    let selectedItem = this.menuItems[this.selectedWindow];
    this.selectedMenuItemValue = selectedItem.value;
  }

  getMenuButtonColor(item) {
    if (this.selectedMenuItemValue == item.value) return this.$store.state.ux.colorAccent3;
    return this.$store.state.ux.colorAccent5;
  }

  get showLeftImage1() {
    return true;
  }

  get showLeftImage2() {
    return false;
  }

  get showRightImage1() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  get showRightImage2() {
    return !this.$vuetify.breakpoint.xsOnly;
  }

  async onSendMessage() {
    this.messageSending = true;

    await rest.url("public/sendMessage")
      .post(this.customerMsg)
      .then((result) => {
        if (result !== true)
          return;

        vuex.ux.SB_SUCCESS({
          message: `Danke für Ihre Kontaktanfrage. Eine automatische Eingangsbestätigung wurde an Ihre Email Adresse ${this.customerMsg.email} versant.\r\nFalls Sie keine Nachricht erhalten haben, überprüfen Sie bitte auch Ihren Spam Email Ordner.`,
          timeout: 15000
        });
        this.customerMsg.firstName = "";
        this.customerMsg.lastName = "";
        this.customerMsg.company = "";
        this.customerMsg.email = "";
        this.customerMsg.phoneNumber = "";
        this.customerMsg.message = "";
        this.gdprAccepted = false;
        this.kontaktFormValid = true;
      })
      .finally(() => {
        this.messageSending = false
      });
  }

  mailRules = [
    (v: string) => !!v || "Email is required",
    (v: string) =>
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) || "Keine gültige E-Mail-Adresse"
  ];

  firstNameRules = [
    (v: string) => !!v || "Vorname erforderlich",
    (v: string) => (v && v.length > 1) || "Vornamename zu kurz."
  ];

  lastNameRules = [
    (v: string) => !!v || "Nachname erforderlich",
    (v: string) => (v && v.length > 1) || "Nachname zu kurz."
  ];

  messageRules = [
    (v: string) => !!v || "Nachricht erforderlich",
    (v: string) => (v && v.length > 9) || "Nachricht zu kurz."
  ];
}
