import { isString } from 'lodash';
// import { vuex } from '@/store';
import Store from "@/store";
import wretch, { WretcherError } from "wretch";
import { RestResponse } from '@/interfaces/RestResponse';
import { ParamDictionary } from '@/interfaces/ParamDictionary';

export default class GlobalHelper {
  constructor() { }

  parseKeysToValue(obj: any, path = '') {
    for (let key in obj) {
      if (typeof obj[key] === 'string') {
        obj[key] = path + key;
      } else {
        path += `${key}.`;
        this.parseKeysToValue(obj[key], path);
        path = path.replace(`${key}.`, '');
      }
    }
  }

  // OS detection
  get isiOs() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  }

  get isAndroid () {
    return navigator.userAgent.match(/Android/i);
  }

  // env
  get isDev() {
    if (window.location.host.startsWith("test.")) {
      return true;
    }
    return false;
  }

  get isLocal() {
    if (window.location.host.includes("local")) {
      return true;
    }
    return false;
  }

  containsOnlyDigits(str) {
    return !isNaN(str) && !isNaN(parseInt(str));
  }

  // tooltip open delay
  get openDelay(): number {
    return 600;
  }

  // utils
  delay(ms = 100) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  scrollToTop() {
    window.scrollTo(0,0);
  }

  async scrollElementToEnd(id) {
    console.log("scrollElementToEnd: ", id);
    await this.delay(100);

    const element = document.getElementById(id);
    if (!element) return;

    element.scrollTop = element.scrollHeight;
  }

  async animateScrollToBottom(id: string, duration: number) {
    // console.log("animateScrollToBottom: ", id);
    await this.delay(100);

    let element = document.getElementById(id);
    if (!element) return;

    // cancel if already on top
    if (element.scrollTop === element.scrollHeight) return;

    const startPos = element.scrollTop;
    const amountToScroll = element.scrollHeight - startPos;
    const cosParameter = amountToScroll / 2;
    // console.log("cosParameter1: ", cosParameter);
    let scrollCount = 0;
    let oldTimestamp = null;

    function step (newTimestamp) {
      // console.log("newTimestamp: ", newTimestamp);
      if (oldTimestamp !== null) {
        // if duration is 0 scrollCount will be Infinity
        scrollCount += Math.PI * (newTimestamp - oldTimestamp) / duration;
        if (scrollCount >= Math.PI) {
          element!.scrollTop = element!.scrollHeight;
          return;
        }
        // console.log("cosParameter: ", cosParameter);
        // console.log("scrollCount: ", scrollCount);
        // console.log("Math.cos(scrollCount): ", Math.cos(scrollCount));
        element!.scrollTop = startPos + cosParameter - cosParameter * Math.cos(scrollCount);
      }
      oldTimestamp = newTimestamp;
      window.requestAnimationFrame(step);
    }
    window.requestAnimationFrame(step);
  }

  async copyToClipboard(value: string) {
    await navigator.clipboard.writeText(value);
    Store.commit("ux/SB_SUCCESS", {
      message: value + " in Zwischenablage kopiert",
      timeout: 2000
    });
  }

  copy(v: string | unknown, interpoled = '') {
    if (!isString(v)) return;
    if (interpoled.includes('v')) {
      v = interpoled.replace('v', v);
    }
    navigator.clipboard.writeText(v as string);
    Store.commit("ux/SB_SUCCESS", {
      message: "In Zwischenablage kopiert",
      timeout: 2000
    });
  }

  // https://attacomsian.com/blog/javascript-download-file
  download(path: string, filename: string) {
    // Create a new link
    const anchor = document.createElement('a');
    anchor.href = path;
    anchor.download = filename;

    // Append to the DOM
    document.body.appendChild(anchor);

    // Trigger `click` event
    anchor.click();

    // Remove element from DOM
    document.body.removeChild(anchor);
  };

  blobDownloadWithAuth(path: string, filename: string, paramDir: ParamDictionary) {
    let token = window.localStorage.getItem("digiClassAuth");
    wretch(path)
    .auth(`Bearer ${ token }`)
    .post(paramDir)
    .blob(data => {
      // const blob = new Blob([data], { type: 'application/pdf' });
      const blob = new Blob([data]);
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      URL.revokeObjectURL(link.href);
    })
    .catch(err => {
      let apiError: RestResponse = JSON.parse(err.text);
      Store.commit("ux/SB_FAILURE", {
        message: `Server Error - ${apiError.description}`,
        timeout: 0
      });
    });
  }

  decodeRouteQueryStr(v: string): any | null {
    if (!v.includes('?')) return null;
    let qd = {};
    qd['route'] = v.split('?')[0];
    let queryStr = v.split('?')[1];
    queryStr.split(`&`).forEach(item => {
      let [k, j] = item.split(`=`);
      j = j && decodeURIComponent(j);
      (qd[k] = qd[k] || []).push(j);
    });
    return qd;
  }

  sendMail(addr: string) {
    window.location.href = 'mailto:' + addr;
  }

  // callTo(addr: string) {
  //   window.location.href = 'callto:' + addr;
  // }

  // imWith(addr: string) {
  //   window.location.href = 'im:' + addr;
  // }
}
