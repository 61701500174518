var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticStyle:{"height":"100%","line-height":"normal"},attrs:{"no-gutters":"","justify":"center","align":"center","flex-nowrap":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","light":""},on:{"click":function($event){$event.stopPropagation();return _vm.onUserPerformanceOverview()}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-chart-areaspline")])],1)]}}])},[_c('span',[_vm._v("Leistungsstand")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":"","small":"","light":""},on:{"click":function($event){$event.stopPropagation();return _vm.onNewMessage()}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-message-outline")])],1)]}}])},[_c('span',[_vm._v("Benachrichtigung an den Anwender schicken")])]),(_vm.isDynamicGroup)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"color":"grey lighten-1","light":"","medium":""},on:{"click":function($event){$event.stopPropagation();}}},on),[_vm._v("mdi-trash-can-outline")])]}}],null,false,3151112671)},[_c('span',[_vm._v("Anwender entfernen ist bei einer dynamischen Gruppe nicht möglich!")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":"","small":"","light":""},on:{"click":function($event){$event.stopPropagation();return _vm.onShowDeletePupilConfirmationDlg()}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-trash-can-outline")])],1)]}}])},[_c('span',[_vm._v("Anwender aus Gruppe entfernen")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }