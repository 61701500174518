
























































































































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch } from "vue-property-decorator";

// Interfaces / Classes
import { ParamDictionary } from "@/interfaces/ParamDictionary";
import { UserFilterSelectionsVm } from "@/interfaces/UserFilterSelectionsVm";
import { PortalUserVM } from "@/interfaces/PortalUserVM";
import { PortalUserVmClass } from "@/classes/PortalUserVmClass";

// Components
import UserTable from "@/components/UserManagement/UserTable.vue";
import CertificateExcelUploadDlg from "@/components/UserManagement/CertificateExcelUploadDlg.vue";
import UserExcelUploadDlg from "@/components/UserManagement/UserExcelUploadDlg.vue";
import UploadMenuButton from "@/components/Shared/UploadMenuButton.vue";
import EditUser from "@/components/UserManagement/EditUser.vue";
import ManageTags from "@/components/UserManagement/ManageTags.vue";

@Component({
  components: {
    UserTable,
    UploadMenuButton,
    CertificateExcelUploadDlg,
    UserExcelUploadDlg,
    EditUser,
    ManageTags
  }
})
export default class UserManagementView extends Vue {
  showCertificateDlg = false;
  showUserExcelUploadDlg = false;
  showEditUserDialog = false;
  paramDir: ParamDictionary = { dictionary: {}};
  searchFilter: UserFilterSelectionsVm = <UserFilterSelectionsVm>{ search: null, withoutGroupMembership: false };
  withoutGroupMembership = false;
  rowCount = 0;
  totalCount = 0;
  excelLoading = false;
  selectedUser: PortalUserVM = new PortalUserVmClass();

  $refs!: {
    userTable: UserTable;
  };

  mounted() {
    // this.$l3rnOnlineHub.sendMessageToServer("User manager opened");

    this.updateTable();
  }

  onAddUser() {
    // console.log("edit user");
    this.selectedUser = new PortalUserVmClass();
    this.showEditUserDialog = true;
  }

  updateTable() {
    this.$refs.userTable.updateTable();
  }

  loadTableData() {
    this.$refs.userTable.loadData();
  }

  setColWidth() {
    this.$refs.userTable.adjustColWidth();
  }

  resetCols() {
    this.$refs.userTable.resetColumns();
  }

  onSearchChanged() {
    this.updateTable();
  }

  @Watch("searchFilter.search")
  async onSearch(val) {
    this.debounce();
  }

  debounce = _.debounce(this.updateTable, 700, { leading: false, trailing: true});

  async onExportUsers() {
    this.excelLoading = true;
    try {
      await this.$refs.userTable.exportUserExcel();
    } finally {
        this.excelLoading = false;
    }
    // await rest.url("orgAdmin/createUserExcel")
    //   .post(this.searchFilter)
    //   .then((fileName) => {
    //     this.$globalHelper.download(`api/resource/Temp/${fileName}`, fileName);
    //   })
    //   .finally(() => {
    //     this.excelLoading = false;
    //   })
  }

  onFileUploaded() {
    this.$refs.userTable.loadData();
  }

  get gridStyle() {
    // if (!this.filtersMenu)
    //   return `width: 100%; height: calc(100vh - 42px - 24px - 48px - 24px);`;
    if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm)
      return `width: 100%; height: calc(100vh - 48px - 40px - 32px - 12px - 56px - 8px - 20px - 36px);`;
    else
      return `width: 100%; height: calc(100vh - 48px - 40px - 12px - 56px - 8px - 20px - 36px);`;
  }
  }
