

























































































































































































































import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import router from "@/router";
import { vuex } from "@/store";

// Components
// import SendMessage from "@/components/SendMessage.vue";
// import EditUser from "@/components/Shared/EditUser.vue";
import AddUsers from "@/components/UserManagement/AddUsers.vue";
import GroupMemberTable from "@/components/Groups/GroupMemberTable.vue";
// import ImportUsers from "@/components/Groups/ImportUsers.vue";
import GroupMembersExcelUploadDlg from "@/components/Groups/GroupMembersExcelUploadDlg.vue";
// import UserQrCodeCard from "@/components/Groups/UserQrCodeCard.vue";

// Interfaces
import { PortalUserVM, SelectListItem } from "@/interfaces/PortalUserVM";
import { GroupVm } from "@/interfaces/GroupVm";
import { ParamDictionary } from "@/interfaces/ParamDictionary";
import { PortalUserVmClass } from "@/classes/PortalUserVmClass";
import { OrganizationVm } from "@/interfaces/OrganizationVm";
import { ParamMemberSuggestionVm } from "@/interfaces/ParamMemberSuggestionVm";
import { MemberTableFilterVm } from "@/interfaces/LoadPartialMemberTableVm";

const globals = namespace("globals");

@Component({
  components: {
    AddUsers,
    GroupMemberTable,
    // SendMessage,
    // ImportUsers,
    GroupMembersExcelUploadDlg
  }
})
export default class UsersView extends Vue {
  @globals.Getter selectedOrg!: OrganizationVm;
  @globals.Getter selectedGroup!: GroupVm;

  breadcrumbItems = [
    { text: 'Start', disabled: false, href: '/home' },
    { text: 'Gruppen', disabled: false, href: '/groups' },
    { text: 'Anwender', disabled: true, href: '/users' },
 ];

  busy = false;
  rowCount = 0;
  totalCount = 0;
  searchFilter = <MemberTableFilterVm>{ groupId: 0, subGroupId: 0, search: "", ascending: true};
  // showQrDialog = false;
  showMessageDialog = false;
  showGroupMembersExcelUploadDlg = false;
  showConfInfo = false;
  showAddUserDialog = false;
  // showPrintQrDialog = false;
  // showDeleteDialog = false;
  // showImportUsersDialog = false;
  isNewUser = true;
  hideFromPrint = false;
  // users: PortalUserVM[] = [];
  selectedUser: PortalUserVM = new PortalUserVmClass();
  selectedQrCode: PortalUserVM | null = null;
  // windowInnerHeight = 0;

  $refs!: {
    groupMemberTable: GroupMemberTable;
  };

  async mounted() {
    // this.setWindowHeight();
    // this.updateUserList();
    if (this.selectedGroup == null) {
      router.push("/");
    }

    this.searchFilter.groupId = this.selectedGroup.id;
    await this.updateTable();
  }

  @Watch("searchFilter.search")
  async onSearch(val) {
    // if (this.init) return;

    this.debounce();
  }

  debounce = _.debounce(this.updateTable, 700, { leading: false, trailing: true});

  // created() {
  //   window.addEventListener("resize", this.setWindowHeight);
  // }

  // beforeDestroy() {
  //   window.removeEventListener("resize", this.setWindowHeight);
  // }

  // setWindowHeight() {
  //   this.windowInnerHeight = window.innerHeight;
  // }

  updateTable() {
    this.$refs.groupMemberTable.updateTable();
  }

  loadTableData() {
    this.$refs.groupMemberTable.loadData();
  }

  setColWidth() {
    this.$refs.groupMemberTable.adjustColWidth();
  }

  resetCols() {
    this.$refs.groupMemberTable.resetColumns();
  }

  onSearchChanged() {
    this.updateTable();
  }

  goBack() {
    router.go(-1);
  }

  // --- User Functions ---
  showGroupPerformanceOverview() {
    router.push("/groupPerformanceOverview");
  }

  onAddPupil() {
    this.isNewUser = true;
    // this.selectedUser = _.cloneDeep(this.defaultUser);
    this.selectedUser = new PortalUserVmClass();
    this.showAddUserDialog = true;
  }

  // onUserPerformanceOverview(user: PortalUserVM) {
  //   if (user == null)
  //     return;

  //   // this.$store.commit('globals/setUser', user);
  //   vuex.globals.setUser(user);
  //   router.push("/userPerformanceOverview");
  // }

  // onNewMessage(user: PortalUserVM) {
  //   if (user == null)
  //     return;

  //   this.selectedUser = user;
  //   this.showMessageDialog = true;
  // }

  // onEditUser(user: PortalUserVM) {
  //   this.isNewUser = false;
  //   this.selectedUser = _.cloneDeep(user);
  //   // this.selectedUser.groupId =  this.selectedGroup.id; // Will be done in EditUser component
  //   this.showEditUserDialog = true;
  // }

  // showDeletePupilConfirmationDlg(user: PortalUserVM) {
  //   if (user == null)
  //     return;

  //   user.groupId = this.selectedGroup.id;
  //   this.selectedUser = user;
  //   this.showDeleteDialog = true;
  // }

  // async onDeleteUser() {
  //   if (this.selectedUser == null)
  //     return;

  //   await rest.url("groupAdmin/deleteUserFromGroup").post(this.selectedUser);
  //   // const index = this.users.indexOf(this.selectedUser);
  //   // this.users.splice(index, 1);
  //   this.updateTable();
  //   this.showDeleteDialog = false;
  // }

  get gridStyle() {
    // if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm)
    //   return `width: 100%; height: calc(100vh - 48px - 40px - 32px - 12px - 56px - 8px - 20px - 36px);`;
    // else
    //   return `width: 100%; height: calc(100vh - 48px - 40px - 12px - 56px - 8px - 20px - 36px);`;
    return `height: calc(100vh - 300px);`
  }

  // get listHeight() {
  //   // if (this.$vuetify.breakpoint.xs)
  //   //   return this.windowInnerHeight - 255;
  //   // else
  //   //   return this.windowInnerHeight * 0.9 - 190;
  //   return this.windowInnerHeight - 265;
  // }

  onShowPrintUserQrCode() {
    vuex.globals.setShowPrintUsersQrCode();
  }
}
